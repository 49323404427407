// const server = 'http://localhost:4001'
const server = 'https://poc.io.ideative.live/'
const socket = io(server);


/**
 * Main send msg method
 * @param channel
 * @param message
 */
function publish(channel, message) {
    console.log('publishing ' + message.todo + ' with value ' + channel);
    socket.emit(channel, message);
}

/**
 *
 * @param channel
 * @param callback
 */
function subscribe(channel, callback) {
    socket.on(channel, callback);
}

/**
 *
 */
function send() {
    var input = document.getElementById('message');
    logChatMsg('Message sent : ' + input.value, 0)
    publish('action', {todo: 'message', value: input.value});
    input.value = '';
}

/**
 *
 * @param msg
 * @param type
 */
function showToast(msg, type) {
    $.toast({
        heading: 'Message',
        text: msg,
        icon: type
    })
}

function logChatMsg(msg, system) {
    if (system) {
        $('<li><em>'+msg+'</em></li>').appendTo($('#messagelist'))
    } else {
        $('<li>'+msg+'</li>').appendTo($('#messagelist'))
    }
}

/**
 *
 */

jQuery(document).ready(function ($) {

    socket.on('init', function (data) {
        showToast('Now connected as MASTER to ' + server, 'success')
        socket.emit('call', {my: 'data'});
    });

    subscribe('userJoined', function (data) {
        console.log('User joined', data);
        $('#numUsers').text(data.toString());
        logChatMsg('New Connection', 1);
    });

    subscribe('userLeft', function (data) {
        console.log('User left', data);
        $('#numUsers').text(data.numUsers.toString());
        logChatMsg('Disconnection: ' + data.reason, 1)
    });

    subscribe('action', function (data) {
        console.log(data.todo + ' ' + data.action);
    });
    $('.controls ul li').off('click').on('click', e => {
        e.preventDefault()
        const action = $(e.currentTarget).data('action')
        if (action === 'galleryTo') {
            const imgNum = $(e.currentTarget).data('num');
            $(e.currentTarget).siblings().removeClass('active').end().addClass('active')
            publish('action', {todo: action, numImage: imgNum})
        } else if (action==='closeGallery') {
            $('.controls ul li').removeClass('active');
            publish('action', {todo: action});
        } else {
            publish('action', {todo: action})
        }
        if ($(e.currentTarget).hasClass('toggle')) {
            $(e.currentTarget).toggleClass('disabled');
        }
    })
});

